import "github-markdown-css";
import React from "react";
import { graphql } from "gatsby";
import styles from "styles/pages/customers.module.css";
import { CompanyInfoCard, TestimonialCard } from "components/customers";
import DefaultLayout from "components/layout/default";
import Img from "components/image";
const ReactMarkdown = require("react-markdown");

const CustomerTestimonial = props => {
  const [stories, setStories] = React.useState([]);
  const { frontmatter: pageData } = props.data.pageContent.childMarkdownRemark;
  const testimonials = props.data.latestTestimonials.edges;
  const currentSlug = props.data.pageContent.childMarkdownRemark.fields.slug;
  React.useEffect(() => {
    const result = testimonials.filter(
      item => item.node.fields.slug !== currentSlug
    );
    setStories(result);
  }, []);
  return (
    <DefaultLayout
      location={props.location}
      title={pageData.header}
      description={pageData.intro}
    >
      <div
        id="page"
        className="flex flex-col items-center justify-center bg-white mt-24 mb-10 pb-20 relative"
        style={{ zIndex: 10 }}
      >
        <section id="top-section" className="w-full xxl:w-2/3 p-10 xxl:p-0">
          <div className="flex flex-col md:flex-row max-w-6xl pt-10 pb-10 text-black">
            <div className="w-full md:w-1/2 pr-5">
              <h3
                id="testimonial-title"
                className="text-center md:text-left lg:text-sm xxl:text-lg uppercase font-bold"
              >
                {pageData.company}
              </h3>
              <h1
                id="testimonial-header"
                className="mt-5 md:mt-0 text-center md:text-left text-xl md:text-2xl xxl:text-5xl font-bold"
              >
                {pageData.header}
              </h1>
              <p
                id="testimonial-intro"
                className="text-center md:text-left mt-5 text-xs xxl:text-sm"
              >
                {pageData.intro}
              </p>
            </div>
            <div className="w-full md:w-1/2 md:pl-5 mt-5 md:mt-0">
              <Img fluid={pageData.mainImage.childImageSharp.fluid} alt={""} />
            </div>
          </div>
        </section>
        <section id="story-section" className="w-full xxl:w-2/3 p-10 xxl:p-0">
          <div className="bg-white flex flex-col pb-10 pl-5 pr-5">
            <div
              id="comapny-row"
              className="flex flex-row items-center text-black bg-white"
            >
              <img
                src={pageData.companyLogoImage.childImageSharp.fluid.src}
                style={{ maxWidth: 100 }}
                alt=""
              />
              <p className="text-xs sm:text-sm font-medium ml-5">
                {pageData.logoText}
              </p>
            </div>
            <hr className="mt-2" />
            <div
              id="the-story"
              className="flex flex-col justify-center w-full mt-10 p-1 sm:p-10"
            >
              <section id="story-markdown" className="mt-5">
                <h3 className="font-bold text-3xl text-black">
                  {pageData.paragraph1Title}
                </h3>
                <div
                  className={`${styles.paragraph_text} text-md markdown-body mt-5`}
                >
                  <ReactMarkdown source={pageData.paragraph1Text} />
                </div>
              </section>
            </div>
          </div>
        </section>
        <section
          id="first-row-images"
          className="w-full mt-16 mb-16 pl-10 pr-10"
        >
          <div className="flex flex-wrap">
            {imageRowRender(pageData.firstRowImages)}
          </div>
        </section>
        <section
          id="engagement-markdown"
          className="w-full xxl:w-2/3 p-10 xxl:p-0 mt-5 text-black"
        >
          <h3 className="font-bold text-3xl">{pageData.paragraph2Title}</h3>
          <div
            className={`text-md markdown-body mt-5 ${styles.paragraph_text}`}
          >
            <ReactMarkdown source={pageData.paragraph2Text} />
          </div>
        </section>
        <div className="w-full mt-16 mb-16 pl-10 pr-10">
          <div className="flex flex-wrap">
            {imageRowRender(pageData.secondRowImages)}
          </div>
        </div>
        <section
          id="testimonail-quote"
          className="w-full xxl:w-2/3 p-10 xxl:p-0 mt-20 mb-20"
        >
          {pageData.testimonialQuote && (
            <TestimonialCard
              image={pageData.testimonialCardImage.childImageSharp.fluid.src}
              quote={pageData.testimonialQuote}
              author={pageData.author}
            />
          )}
          <section id="result-markdown" className="mt-5">
            <h3 className="font-bold text-3xl text-black">
              {pageData.paragraph3Title}
            </h3>
            <div
              className={`text-md markdown-body mt-5 ${styles.paragraph_text}`}
            >
              <ReactMarkdown source={pageData.paragraph3Text} />
            </div>
          </section>
          <section id="explore-testimonials" className="mt-32">
            <h1 className={`${styles.outline_text} text-3xl`}>Explore :</h1>
            <h1
              style={{ color: "rgb(47, 64, 255)" }}
              className={`text-xl md:text-3xl font-bold`}
            >
              Other Customer Stories
            </h1>
            <div className="flex flex-col items-center lg:flex-row text-black w-full">
              {renderTestimonials()}
            </div>
          </section>
        </section>
      </div>
    </DefaultLayout>
  );

  function imageRowRender(images) {
    const total = images.length;
    return images.map((image, index) => {
      return (
        <div key={index} className={`${handleImageClass(total, index)} p-2`}>
          <Img fluid={image.url.childImageSharp.fluid} alt={""} />
        </div>
      );
    });
  }

  function handleImageClass(total, current) {
    if (total === 1) {
      return "w-full";
    } else if (current % total !== 0) {
      return "w-1/2 mx-auto";
    } else {
      return "w-1/2";
    }
  }

  function renderTestimonials() {
    return stories.map((story, index) => {
      const { header } = story.node.frontmatter;
      const { slug } = story.node.fields;
      const testimonialCardImage =
        story.node.frontmatter.testimonialCardImage.childImageSharp.fluid;
      const logo =
        story.node.frontmatter.companyLogoImage.childImageSharp.fluid;
      return (
        <div
          key={index}
          className={`w-1/3 mt-10 mr-5 flex justify-center md:justify-start`}
        >
          <CompanyInfoCard
            className="w-80 h-92"
            image={testimonialCardImage.src}
            logo={logo.src}
            text={header}
            url={slug}
          />
        </div>
      );
    });
  }
};

export const QUERY = graphql`
  query CustomerTestimonialQuery($fileId: String!) {
    pageContent: file(id: { eq: $fileId }) {
      childMarkdownRemark {
        id
        html
        fields {
          slug
        }
        excerpt(pruneLength: 400)
        frontmatter {
          company
          header
          intro
          logoText
          paragraph1Title
          paragraph1Text
          paragraph2Title
          paragraph2Text
          paragraph3Title
          paragraph3Text
          testimonialQuote
          author
          mainImage {
            childImageSharp {
              fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          testimonialCardImage {
            childImageSharp {
              fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          companyLogoImage {
            childImageSharp {
              fluid(maxWidth: 1280, toFormat: PNG, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          firstRowImages {
            url {
              childImageSharp {
                fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          secondRowImages {
            url {
              childImageSharp {
                fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    latestTestimonials: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//content/customer-testimonials//" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt(pruneLength: 400)
          frontmatter {
            header
            companyLogoImage {
              childImageSharp {
                fluid(maxWidth: 1280, toFormat: PNG, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            testimonialCardImage {
              childImageSharp {
                fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CustomerTestimonial;
